/* ====== TO TOP ====== */
jQuery(document).ready(function($){
    var offset = 100,
    offset_opacity = 1200,
    scroll_top_duration = 700,
    $back_to_top = $('.cd-top');
    $(window).scroll(function(){
        ( $(this).scrollTop() > offset ) ? $back_to_top.addClass('cd-is-visible') : $back_to_top.removeClass('cd-is-visible cd-fade-out');
        if( $(this).scrollTop() > offset_opacity ) { 
            $back_to_top.addClass('cd-fade-out');
        }
    });
    $back_to_top.on('click', function(event){
        event.preventDefault();
        $('body,html').animate({
            scrollTop: 0
            }, scroll_top_duration
        );
    });
});

/* ====== Slider ====== */
$(window).load(function(){$(".flexslider").flexslider({directionNav:true, controlNav: true, animation:"fade", animationLoop:!0, pauseOnHover:!1, keyboard:!0});});

/* ====== SLICK TESTIMONIAL ====== */
// $('.testimonials').slick({
//     infinite: true,
//     autoplay: true,
//     arrows: false,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplaySpeed: 7000,
//     responsive: [
//         {
//             breakpoint: 1024,
//             settings: {
//                 autoplaySpeed: 6000,
//                 slidesToShow: 3,
//                 slidesToScroll: 1
//             }
//         },
//         {
//             breakpoint: 1024,
//             settings: {
//                 autoplaySpeed: 6000,
//                 slidesToShow: 2,
//                 slidesToScroll: 1
//             }
//         },
//         {
//             breakpoint: 768,
//             settings: {
//                 autoplaySpeed: 6000,
//                 slidesToShow: 1,
//                 slidesToScroll: 1
//             }
//         }
//     ]
// });

/* ====== Contact Validation ====== */
jQuery(function ($) {
    $.validator.addMethod("customemail",
        function (value, element) {
            return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.(rs|com|cc|info|live|org|io|studio|shop|biz|in|hr|website|me|co\.(in|uk|rs)|org\.(rs)|edu\.(rs)|me|org|net|edu|gov|info|срб)$/.test(value);
        },
        "Email adresa nije uneta u ispravnom formatu"
    );
    $('#btn-submit').click(function () {
        var $captcha = $('#recaptcha'),
            response = grecaptcha.getResponse();

        if (response.length === 0) {
            $('.msg-error').text("reCAPTCHA je obavezna");
            if (!$captcha.hasClass("error")) {
                $captcha.addClass("error");
            }
        } else {
            $('.msg-error').text('');
            $captcha.removeClass("error");
        }
    });

    $("#contact_form_nt").validate({
        rules: {
            ime: {
                required: true
            },
            prezime: {
                required: true
            },
            email: {
                required: true,
                email: true,
                customemail: true
            },
            telefon: {
                required: true
            },
            poruka: {
                required: true
            }
        },
        messages: {
            ime: "Unesite vaše ime",
            prezime: "Unesite vaše prezime",
            email: {
                required: "Unesite vašu email adresu",
                email: "Email adresa nije uneta u ispravnom formatu"
            },
            telefon: "Unesite vaš broj mobilnog telefona",
            poruka: "Unesite vašu poruku"
        }
    });
});